<template>
  <div style="width: 100%;">
    <v-container>
      <v-alert
        text color="red lighten-1" outlined prominent type="error"
      >
        <h3 class="text-h5">
          Error al abrir acceso
        </h3>
        <div class="pt-4">
          Se ha producido un error al intentar abrir el acceso.
        </div>
        <v-divider
          class="my-4 red"
        ></v-divider>
        <div>
          Si el problema persiste contáctese con la administración o con <a :href="mailto()">{{ whiteLabel.labels.brand}}</a>
        </div>
      </v-alert>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
  }),

  computed: {
    ...mapGetters({
      whiteLabel: "whiteLabel",
    })
  },
  
  methods: {
    mailto() {
      return "mailto:" + this.whiteLabel.domains.email;
    },
  },
};
</script>