<template>
  <div style="width: 100%">
    <v-overlay :value="loading" z-index=10 class="text-center">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
      <div class="pt-15">{{ loading_message }}</div>
    </v-overlay>
    <v-row no-gutters>
      <v-col cols="12" v-if="validKey && validLocation && validDistance">

        <v-container>
        <v-card class="mx-auto">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-check-decagram-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="headline">
                  Llave "{{ key.name }}" válida
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <div class="text-center pt-16">Presione el botón para abrir el acceso</div>
            <div class="text-center pt-8 pb-16">
              <v-btn
              :color="whiteLabel.colors.primary"
              fab
              x-large
              dark
              @click="openDoorKey(code)"
              >
                <v-icon>mdi-door-open</v-icon>
              </v-btn>
            </div>
            <div class="pt-8">
              <v-container>
              <v-alert
                text color="red lighten-1" outlined prominent type="error"
              >
                <h4 class="text-caption">
                  ATENCIÓN !!!
                </h4>
                <div class="pt-4 text-caption">
                  La señal de apertura puede demorar unos pocos segundos en llegar.<br>
                  Por favor, esté atento a ingresar cuando el acceso se abra.
                </div>
                <v-divider
                  class="my-4 red"
                ></v-divider>
                <div class="text-caption">
                  Si se produce algun problema, vuelva a ingresar con el link de la llave virtual que le fue compartido.
                </div>
              </v-alert>
            </v-container>
            </div>
          </v-card>
        </v-container>

      </v-col>
      <v-col class="scroll-height">
        <v-main>
          <v-container>

            <v-alert
              text color="red lighten-1" outlined prominent v-if="retryLocation" type="error"
            >
              <h3 class="text-h5">
                Su ubicación no pudo ser establecida
              </h3>
              <div class="pt-4">Se agotó el tiempo para establecer su ubicación.<br>
              Asegúrese de tener los servicios de localización habilitados y de haber permitido el uso de la localización cuando le fue requerido.</div>
              <v-divider
                class="my-4 red"
              ></v-divider>
              <v-btn
                color="primary" outlined elevation="2" large @click="getLocation"
              >
                <v-icon dark class="pr-4">
                  mdi-crosshairs-gps
                </v-icon>
                Reintentar
              </v-btn>
            </v-alert>

            <v-alert
              text color="red lighten-1" outlined prominent v-if="!initial_run && !validKey" type="error"
            >
              <h3 class="text-h5">
                Llave Virtual inválida
              </h3>
              <div class="pt-4">
                La llave contiene un código incorrecto o no es válida por alguno de los siguientes motivos:<br>
                <ul>
                  <li>La fecha límite para su uso ya expiró</li>
                  <li>La cantidad máxima de usos fue alcanzada</li>
                  <li>El dia de la semana y horario no permiten usarla en este momento</li>
                </ul>
                <br>
                Solicite al residente que le envió esta llave virtual que la revise o envíe una nueva.
              </div>
              <v-divider
                class="my-4 red"
              ></v-divider>
              <div>
                Si el problema persiste contáctese con la administración o con <a :href="mailto()">{{ whiteLabel.labels.brand}}</a>
              </div>
            </v-alert>

            <v-alert
              text color="red lighten-1" outlined prominent v-if="validKey && !validDistance" type="error"
            >
              <h3 class="text-h5">
                La distancia al frente de calle QR Virtual es inválida
              </h3>
              <div class="pt-4">
                Su ubicación actual se encuentra a una distancia mayor a la máxima permitida con el frente TappBell Virtual.<br>
                Por favor, acérquese y vuelva a escanear el QR.<br><br>
                Distancia al frente ~ {{distance}} mts. Distancia máxima {{building.location.range}} mts.
              </div>
              <v-divider
                class="my-4 red"
              ></v-divider>
              <div>
                Si el problema persiste contáctese con la administración o con <a :href="mailto()">{{ whiteLabel.labels.brand}}</a>
              </div>
            </v-alert>
          </v-container>
        </v-main>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "../store";
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(Vuex)
Vue.use(VueAxios, axios)

export default {

  data: () => ({
    current_location: {
      lat: null,
      lng: null,
    },
    validLocation: false,
    errorMessage: null,
    retryLocation: false,
    loading: true,
    loading_message: null,
    validKey: false,
    distance: null,
    validDistance: true,
    initial_run: true,
    code: '',
  }),

  created() {},

  mounted() {
    if (!this.$route.query.code) {
      this.$router.push({ name: 'invalid'});
    }
    this.code = encodeURIComponent(this.$route.query.code);
    this.getKeysData(this.code);
  },

  computed: {
    ...mapGetters({
      building: "building",
      key: "key",
      whiteLabel: "whiteLabel",
    }),
    mini() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  
  methods: {
    async getKeysData(code) {
      this.loading_message = 'Analizando llave ...';
      this.loading = true;
      await store.dispatch("fetchKeysData", code);
      if (this.key) {
        this.validKey = true;
        await this.getLocation();
      } else {
        this.validKey = false;
      }
      this.initial_run = false;
      this.loading = false;
    },
    
    async getLocation() {
      try {
        if (this.building.location.range > 0) {
          this.validLocation = false;
          this.retryLocation = false;
          this.loading_message = 'Obteniendo ubicación ...';
          this.loading = true;
          await this.$getLocation({enableHighAccuracy: true,timeout: 4000, maximumAge: 0})
            .then(coordinates => {
              this.current_location = coordinates;
              this.errorMessage = null;
              this.loading = false;
              this.distance = Math.round(this.getDistance(this.current_location.lat, this.current_location.lng, this.building.location.lat, this.building.location.lng));
              //console.log('Distancia entre el dispositivo y el QR', this.distance, 'metros');
              if (this.distance <= this.building.location.range) {
                this.validDistance= true;
                this.validLocation = true;
              } else {
                this.validDistance = false;
              }
            })
            .catch(error => {
              this.retryLocation = true;
              this.errorMessage = error;
              this.validLocation = false;
              this.loading = false;
            });
        } else {
          this.validDistance= true;
          this.validLocation = true;
          //console.log('Distancia entre el dispositivo y el QR ilimitada');
        }

      } catch (error) {
        this.loading = false;
      }
    },

    getDistance(lat1, lon1, lat2, lon2) {
      var R = 6371; // Radius of the earth in km
      var dLat = this.deg2rad(lat2-lat1);
      var dLon = this.deg2rad(lon2-lon1);
      var a = 
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * 
        Math.sin(dLon/2) * Math.sin(dLon/2)
        ; 
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
      var d = R * c * 1000; // Distance in meters
      return d;
    },

    deg2rad(deg) {
      return deg * (Math.PI/180);
    },

    async openDoorKey(code) {
      this.loading_message = 'El acceso esta siendo abierto en este momento ... Puede inresar ...';
      this.loading = true;
      
      const data=new URLSearchParams() 
      data.append("door","1");
      data.append("image", "");
      await Vue.axios.post("https://us-central1-tappbellcloud.cloudfunctions.net/openDoorKey/" + code,
        data,
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        }
      )
      .then((response) => {
          if (response.status == 200) {
            this.loading_message = '';
            this.loading = false;
            this.$router.push({ name: 'open'});
          } else {
            this.loading_message = '';
            this.loading = false;
            this.$router.push({ name: 'error'});
          }
      })
      .catch((error) => {
        console.log('catched error', error);
        this.loading_message = '';
        this.loading = false;
        this.$router.push({ name: 'error'});
      });
    },

    mailto() {
      return "mailto:" + this.whiteLabel.domains.email;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list-item--active {
  &::after {
    width: 0;
    height: 0;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    border-right: 25px solid white;
    position: absolute;
    right: -25px;
  }
}
.theme--dark.v-navigation-drawer .v-divider {
	border-color: white !important;
}
</style>
