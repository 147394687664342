<template>
  <div style="width: 100%;">
    <v-container>
      <v-alert
        text color="green lighten-1" outlined prominent type="success"
      >
        <h3 class="text-h5">
          Apertura finalizada
        </h3>
        <div class="pt-4">
          La apertura del acceso finalizó con éxito.
        </div>
        <v-divider
          class="my-4 green"
        ></v-divider>
        <div>
          Verifique que el acceso haya quedado bien cerrado luego de su ingreso. Gracias!
        </div>
      </v-alert>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
  }),

  computed: {
    ...mapGetters({
      whiteLabel: "whiteLabel",
    })
  },
  
  methods: {
    mailto() {
      return "mailto:" + this.whiteLabel.domains.email;
    },
  },
};
</script>